<template>
  <div class="container content-p">
    <component :is="cohortData === undefined ? 'div' : 'b-card'">
      <!-- Alert: No item found -->
      <b-alert variant="danger" :show="cohortData === undefined">
        <h4 class="alert-heading">Error fetching cohort data</h4>
        <div class="alert-body">
          No cohort found with this cohort id. Check
          <b-link class="alert-link" :to="{ name: 'apps-roster-cohort-list-pending' }"> Cohort List </b-link>
          for other cohorts.
        </div>
      </b-alert>

      <b-tabs v-if="cohortData" pills>
        <!-- Tab: Information -->
        <b-tab>
          <template #title>
            <feather-icon icon="InfoIcon" size="16" class="mr-0 mr-sm-50" />
            <span class="d-none d-sm-inline">Information</span>
          </template>
          <cohort-edit-tab-information :cohort-data.sync="cohortData" class="mt-2 pt-75" />
        </b-tab>

        <!-- Tab: Sessions -->
      </b-tabs>
    </component>
  </div>
</template>

<script>
import { BTab, BTabs, BCard, BAlert, BLink } from 'bootstrap-vue';
import { ref, onUnmounted } from '@vue/composition-api';
import router from '@/router';
import store from '@/store';
import CohortEditTabInformation from './CohortEditTabInformation.vue';

export default {
  components: {
    BTab,
    BTabs,
    BCard,
    BAlert,
    BLink,

    CohortEditTabInformation,
  },
  setup() {
    const cohortData = ref(null);

    store
      .dispatch('receivableStoreModule/fetchCohort', { id: router.currentRoute.params.id })
      .then((response) => {
        let { data } = response;
        if (!data.program) data.program = {};
        cohortData.value = data;
      })
      .catch((error) => {
        if (error.response.status === 404) {
          cohortData.value = undefined;
        }
      });

    return {
      cohortData,
    };
  },
};
</script>

<style scoped lang="scss"></style>
